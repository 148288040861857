import React, { useState, useEffect } from "react";

const AlexaPrivacyPolicy = () => {
  return (
    <div className="container">
      <header className="jumbotron">
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontFamily: 'Comic Sans MS', fontSize: 28 }}>
          <h1>{"Alexa Skill Privacy Policy"}</h1>
        </div>
        <p>
          <br /><br />
          <strong>Effective Date:</strong> 10-06-2024
          <br /><br />
          <strong>1. Introduction</strong>
          <br />
          Thank you for using our Alexa skill. This Privacy Policy explains how we collect, use, disclose, and protect your information when you use our Alexa skill, which connects to the "Landlord Automations" backend.
          <br /><br />
          <strong>2. Information We Collect</strong>
          <br />
          When you use our Alexa skill, we collect the following information:
          <br />
          - <strong>Email Address</strong>: We collect and store your email address to identify and authenticate your account with "Landlord Automations."
          <br />
          - <strong>Password</strong>: We collect and store your password to provide secure access to your "Landlord Automations" account.  Just like our main database, the password you use for this Alexa skill is fully encrypted and cannot be read even by our employees.
          <br /><br />
          <strong>3. How We Use Your Information</strong>
          <br />
          We use the information we collect for the following purposes:
          <br />
          - <strong>Authentication and Access</strong>: To authenticate your identity and provide you access to your "Landlord Automations" account through the Alexa skill.
          <br />
          - <strong>Service Improvement</strong>: To improve and personalize the functionality of our Alexa skill.
          <br /><br />
          <strong>4. Information Sharing and Disclosure</strong>
          <br />
          We do not share your personal information with any third parties except in the following circumstances:
          <br />
          - <strong>Legal Requirements</strong>: We may disclose your information if required by law or in response to a legal process.
          <br />
          - <strong>Protection of Rights</strong>: We may disclose your information to protect our rights, property, or safety, or the rights, property, or safety of others.
          <br /><br />
          <strong>5. Data Retention and Deletion</strong>
          <br />
          We retain your email and password for as long as you use our Alexa skill. If you stop using the system, we will delete all your information from our records.  You can also contact us directly to ask for the deletion of your records.
          <br /><br />
          <strong>6. Security</strong>
          <br />
          We implement reasonable security measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee absolute security.
          <br /><br />
          <strong>7. Your Rights</strong>
          <br />
          You have the right to access, update, or delete your personal information. To exercise these rights, please contact us at Landlordcontroller@gmail.
          <br /><br />
          <strong>8. Changes to This Privacy Policy</strong>
          <br />
          We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. Your continued use of our Alexa skill after any changes signifies your acceptance of the updated policy.
          <br /><br />
          <strong>9. Contact Us</strong>
          <br />
          If you have any questions or concerns about this Privacy Policy, please contact us at:
          <br />
          - Email: Landlordcontroller@gmail
          <br /><br />
          <strong>10. Consent</strong>
          <br />
          By using our Alexa skill, you consent to the collection, use, and sharing of your information as described in this Privacy Policy.
        </p>
      </header>
    </div>
  );
};

export default AlexaPrivacyPolicy;
