import axios from "axios";
import authHeader from "./auth-header";
import {
  LOGIN_SUCCESS,
} from "../actions/types";

const BE_URI = process.env.REACT_APP_BE_URI;

const getPublicContent = (token) => {

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
    authorization: `Bearer ${token}`,
  };

  return axios.get(BE_URI + "/fetch-general-news", { headers });
};

const uploadProfileImage = async (file, currentUser, dispatch ) => {
  
  const formData = new FormData();
  formData.append('profile', file);

  try {
    const res = await axios.post(BE_URI + '/upload-profile', formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        authorization: `JWT ${currentUser.token}`,
      },
    });

    if (res.data.success) {
      const updatedUser = {
        ...currentUser,
        user: {
          ...currentUser.user,
          avatar: res.data.avatar,
        },
      };
  
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: updatedUser },
      });
    } else {
      console.log("Error uploading profile picture");
    }
  } catch (error) {
    console.log("Error uploading profile picture");
    console.log(error.message);
    //setIsLoading(false);
  }
};

const getModeratorBoard = () => {
  return axios.get(BE_URI + "mod", { headers: authHeader() });
};

const getAdminBoard = () => {
  return axios.get(BE_URI + "admin", { headers: authHeader() });
};

export default {
  getPublicContent,
  getModeratorBoard,
  getAdminBoard,
  uploadProfileImage,
};