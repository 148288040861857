import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import UserService from "../services/user.service";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const MyImagePicker = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 0) {
        setLoading(true);
        UserService.uploadProfileImage(acceptedFiles[0], currentUser, dispatch).then(()=>{
            props.onClose();
            setLoading(false);
        });
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*' // Accept all image types
  });



  return (
    <div {...getRootProps()} style={{ border: '2px dashed #000', padding: '20px', width: '250px', height: '250px', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
      {loading ? (
          <span className="spinner-border spinner-border-sm" style={{ width: '120px', height: '120px', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}></span>
      ) : (
        <>
          <input {...getInputProps()} />
          <p>Drag 'n' drop some image files here, or click to select files</p>
        </>
      )}
    </div>
  );
};

export default MyImagePicker;
