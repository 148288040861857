import { Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import noPicture from '../images/empty_image.png';
import MyImagePicker from '../component/MyImagePicker.js';
import React, { useState, useEffect, useRef } from "react";

const Profile = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [showImagePicker, setShowImagePicker] = useState(false);
  const imagePickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (imagePickerRef.current && !imagePickerRef.current.contains(event.target)) {
        setShowImagePicker(false);
      }
    };

    if (showImagePicker) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showImagePicker]);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  const toggleImagePicker = () => {
    setShowImagePicker(true);
  };

  return (
    <div className="container">
      <header className="jumbotron">
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        {!showImagePicker ? <img onClick={toggleImagePicker} src={!currentUser.user.avatar? noPicture : currentUser.user.avatar} style={{ width: '250px', height: '250px', marginBottom: '10px' }} alt="Profile" />
          : <div ref={imagePickerRef}><MyImagePicker onClose={() => setShowImagePicker(false)}  /></div>}
          <h3>
          Profile: <strong>{currentUser.user.fullname}</strong> 
          </h3>
        </div>
      </header>
      <p>
        <strong>Token:</strong> {currentUser.token.substring(0, 20)} ...{" "}
        {currentUser.token.substr(currentUser.token.length - 20)}
      </p>
      <p>
        <strong>Email:</strong> {currentUser.user.email}
      </p>
      <ul>
        {currentUser.user.groupsOwner &&
          currentUser.groupsOwner.map((group, index) => <li key={index}>{group}</li>)}
      </ul>
    </div>
  );
};

export default Profile;
