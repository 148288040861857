import axios from "axios";

const BE_URI = process.env.REACT_APP_BE_URI;

const register = (fullname, email, password, confirmPassword) => {
  return axios.post(BE_URI + "/create-user", {
    fullname,
    email,
    password,
    confirmPassword
  });
};

const login = (username, password) => {
  return axios
    .post(BE_URI + "/sign-in", {
      email: username,
      password: password,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = (token, navigate) => {

  
  const config = {
    headers: {Authorization: `Bearer ${token}`},
  };

  return axios
    .post(BE_URI + "/sign-out", {}, config)
    .then((response) => {
      localStorage.removeItem("user");
      navigate("/login");
    }).catch((error) => {
      console.error("Error during logout:", error); 
      localStorage.removeItem("user");
      navigate("/login");
    });
};

export default {
  register,
  login,
  logout,
};
