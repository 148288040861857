import React, { useState, useEffect } from "react";

const BoardAdmin = () => {

  return (
    <div className="container">
      <header className="jumbotron">
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <h1>{'Admin board'}</h1>
        </div>
      </header>
      <div>
        <p style={{fontFamily: 'Comic Sans MS', fontSize: 22}}>
          This section is underconstruction...
        </p>
      </div>
    </div>
  );
};

export default BoardAdmin;
