import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import iconImage from '../images/ic_nobg.png';
import UserService from "../services/user.service";

const Home = () => {
  const [content, setContent] = useState("");
  const { user: currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    UserService.getPublicContent(currentUser.token).then(
      (response) => {
        setContent(response.data.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
      }
    );
  }, []);

  return (
    <div className="container">
      <header className="jumbotron">
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <img src={iconImage} alt="iconImage" style={{width: 350, height: 250}} />
          <h3>{content}</h3>
        </div>
      </header>
    </div>
  );
};

export default Home;
