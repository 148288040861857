import React, { useState, useEffect } from "react";
import iconImage from '../images/ic_nobg.png';
import androidIconImage from '../images/Android_logo.png';
import alexaImage from '../images/alexa.jpg';
import { useSelector } from "react-redux";
import UserService from "../services/user.service";
import { Link } from "react-router-dom";


const GeneralHome = () => {
  const [content, setContent] = useState("");
  /*const { user: currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    UserService.getPublicContent(currentUser.token).then(
      (response) => {
        setContent(response.data.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
      }
    );
  }, []);*/

  return (
    <div className="container">
      <header className="jumbotron">
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <img src={iconImage} alt="iconImage" style={{width: 350, height: 250}} />
          <h1>{'Landlord Automations'}</h1>
        </div>
      </header>
      <div>
        <p style={{fontFamily: 'Comic Sans MS', fontSize: 22}}>
          Welcome to <strong>Landlord Automations</strong>, where innovation meets connectivity. As a startup in the Internet of Things industry, we are committed to expanding the horizons of smart technology by constantly integrating new devices into our interconnected ecosystem. Our mission is to seamlessly blend cutting-edge advancements with everyday functionality, transforming how you interact with the world around you. From smart homes, farms automation and beyond, we strive to enhance your life through an ever-growing fleet of intelligent, responsive, and efficient devices. Join us in shaping a smarter future, one connection at a time.
        </p>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <img src={androidIconImage} alt="androidIconImage" style={{width: 350, height: 350}} />
          <p style={{fontFamily: 'Comic Sans MS', fontSize: 22}}>{'An android app is already available to control your devices'}</p>
          <a href={"https://play.google.com/store/apps/details?id=com.home_controller&hl=en_CA"} className="nav-link">
            Download android app
          </a>
          <img src={alexaImage} alt="alexaImage" style={{width: 500, height: 350}} />
          <p style={{fontFamily: 'Comic Sans MS', fontSize: 22}}>{'We also offer integration with Amazon\'s alexa!'}</p>
          <Link to={"/alexaprivacy"} className="nav-link">
            Privacy policy
          </Link>
          <p style={{fontFamily: 'Comic Sans MS', fontSize: 22}}>
          New features will be available in the next few months! stay tuned for more!
        </p>
        </div>
        
      </div>
    </div>
  );
};

export default GeneralHome;
